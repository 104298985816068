import * as React from "react"
import { Link } from "gatsby"
import "../assets/css/success.css"
import Layout from "../components/Layout"

export const Head = () => (
  <>
    <meta name="description" content="Success" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale = 1.0, 
          maximum-scale=1.0"
    ></meta>
    <title>HI9 STUDIOS | SUCCESS</title>
  </>
)

const SuccessPage = () => (
    <Layout>
      <div class="success-page">
        <h1 class="success-title">SUCCESS</h1>
        <Link to="/home" className="success-link">
          <button class="home-button">HOME</button>
        </Link>
      </div>
    </Layout>
)

export default SuccessPage
